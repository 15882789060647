:root {
  /* cloud brand color palette */
  --cloudGreen: #90c7b0;
  --cloudBlue: #2ac2e9;
  --cloudTeal: #146878;
  --tealLight: #8db2b9;
  --tealLighter: #76a0a8;

  --white: #fff;

  --cloudFontGreenTeal: #1c928e;
  --cloudFontGreen: #25928d;
  --borderLightTeal: #8db3bb;
  --cloudCharcoalFont: #666;
  --cloudGreyFont: #58585a;
  --cloudGreenFont: #3cbca0;
  --defaultWhiteFont: --white;
  --defaultBrownFont: #58585a;
  --cloudBlackFont: #231f20;

  --alertRed: #dd6346;
  --successGreen: #2a9606;

  --defaultDarkBackgroundColor: #231f20;
  --darkGreyBackground: #58585a;
  --defaultLightBackgroundColor: #fbfbfb;
  --lightGreyBackground: #dbdbdb;
  --defaultGreyBorderColor: #a6a19c;
  --defaultBoxBackgroundColor: #ecedef;
  --defaultFieldFont: #ccc;

  --adminToolButtonSelect: #8cb2ba;

  --gridUnit: 20px;

  --tabSelectorHeight: calc(2 * var(--gridUnit));

  /* Additional palette colors from Phase 0 style library */
  --tealSelectable: #156777;
  --tealSelected: #cbdde1;
  --tealDisabled: #8eaeb2;
  --redAccentButton: #db3f35;
  --redAccentText: #5b0c0a;
  --grayDarkForeground: #231f20;
  --grayLightBackground: #f7f7f7;
  --brownLightAccent: #bbb1a7;

  /* Font Faces */
  --font--system: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

  --color-default: #849fbb;
  --color-default-light: #dddddd;
  --color-active: #57cb85;
  --color-active-light: #9effc4;
  --color-focus: #6fb5fb;

  --margin-el: 7px;
}
